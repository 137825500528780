import React, { useCallback, useEffect, useState } from "react";
import "../App.css";
import { Button, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import { useNavigate, useParams } from "react-router-dom";
import { api, baseURL } from "../utils/api";
function Preview() {
  const { id } = useParams();
  const [formdata, setformdata] = useState({});
  const navigate = useNavigate();
  console.log("baseURL", baseURL);
  const fetchApplicationDetail = useCallback(async () => {
    if (!id) return;
    try {
      const response = await api.get(`/api/srilanka/View-application/${id}`);
      if (response.data && response.data.status) {
        console.log("🚀 ~ fetchApplicationDetail ~ response:", response.data);
        setformdata(response.data.data);
      }
    } catch (error) {
      alert(error.response.data.message || "something went wrong");
    }
  }, [id]);
  useEffect(() => {
    fetchApplicationDetail();
  }, [fetchApplicationDetail]);
  const handleEdit = () => {
    navigate(`/Apply/${id}`);
  };

  const Gopayment = () => {
    navigate(`/payment/${id}`);
  };
console.log("krishna===ms",formdata)
  return (
    <>
      {" "}
      {formdata && (
        <div className="gsrilanka">
          <div className="centerCol">
            <div className="welcomeText">
              <Container className="pagesa4">
                <h3> Preview Your Application </h3>

                <Row className="justify-content-md-center m-2">
                  <Col sm={5}>
                    <Form.Label htmlFor="basic-url">
                      {" "}
                      <b> Application Number </b>{" "}
                    </Form.Label>
                  </Col>
                  <Col sm={4}>
                    <p>
                      {" "}
                      <b> {formdata.sku_id}</b>{" "}
                    </p>
                  </Col>
                </Row>

                <Container className="pagesa4">
                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url">
                        Visa Type{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <p>
                        {" "}
                        <b> {formdata.visaType}</b>{" "}
                      </p>
                    </Col>
                  </Row>

                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url">
                        Passport Type{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <p>
                        {" "}
                        <b> {formdata.PassportType}</b>{" "}
                      </p>
                    </Col>
                  </Row>

                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url">
                        Surname/Family Name{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <p>
                        {" "}
                        <b> {formdata.surname}</b>{" "}
                      </p>
                    </Col>
                  </Row>

                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url">
                        Other/Given Names{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <p>
                        {" "}
                        <b> {formdata.other_name}</b>{" "}
                      </p>
                    </Col>
                  </Row>

                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url">
                        Title{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <p>
                        {" "}
                        <b> {formdata.title}</b>{" "}
                      </p>
                    </Col>
                  </Row>

                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url">
                        Date of Birth{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <p>
                        {" "}
                        <b> {formdata.date_of_birth}</b>{" "}
                      </p>
                    </Col>
                  </Row>

                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url">
                        Gender{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <p>
                        {" "}
                        <b> {formdata.gender}</b>{" "}
                      </p>
                    </Col>
                  </Row>

                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url">
                        {" "}
                        Country or Region of Birth{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <p>
                        {" "}
                        <b> {formdata.Country_of_birth}</b>{" "}
                      </p>
                    </Col>
                  </Row>

                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url">
                        Nationality{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <p>
                        {" "}
                        <b> {formdata.nationality}</b>{" "}
                      </p>
                    </Col>
                  </Row>

                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url">
                        {" "}
                        Email{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <p>
                        {" "}
                        <b> {formdata.email}</b>{" "}
                      </p>
                    </Col>
                  </Row>

                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url">Occupation </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <p>
                        {" "}
                        <b> {formdata.Occupation}</b>{" "}
                      </p>
                    </Col>
                  </Row>

                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url">
                        Passport Number{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <p>
                        {" "}
                        <b> {formdata.passport_number}</b>{" "}
                      </p>
                    </Col>
                  </Row>

                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url">
                        Passport Issued Date{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <p>
                        {" "}
                        <b> {formdata.passport_issued_date}</b>{" "}
                      </p>
                    </Col>
                  </Row>

                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url">
                        Passport Expiry Date{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <p>
                        {" "}
                        <b> {formdata.passport_expiry_date}</b>{" "}
                      </p>
                    </Col>
                  </Row>

                  <div className="formvisa_apply">
                    Child information in parent's passport
                  </div>
                  <p>
                    {" "}
                    Children under 12 years of age possessing separate
                    passport(s) should submit individual application(s)
                  </p>

                  {Array.isArray(formdata.children) &&
                    formdata.children.length > 0 && (
                      <div style={{ marginTop: "20px" }}>
                        <Table hover>
                          <thead>
                            <tr>
                              <th>Children</th>
                              <th>Surname</th>
                              <th>Given Names</th>
                              <th>Date of Birth</th>
                              <th>Gender</th>
                              <th>Relationship</th>
                            </tr>
                          </thead>
                          <tbody>
                            {formdata.children.map((child, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{child.child_surname}</td>
                                <td>{child.child_givenName}</td>
                                <td>{child.child_date_of_birth}</td>
                                <td>{child.child_gender}</td>
                                <td>{child.child_relationship}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    )}

                  <div className="formvisa_apply">Travel Information</div>

                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url">
                        Where you have been during last 14 days before this
                        travel{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <p>
                        {" "}
                        <b> {formdata.days_14_days_before_this_travel}</b>{" "}
                      </p>
                    </Col>
                  </Row>

                  {formdata && formdata.visaType === "BUSINESS VISA" ? null : (
                    <Row className="justify-content-md-center m-2">
                      <Col sm={5}>
                        <Form.Label htmlFor="basic-url">
                          {" "}
                          Visa Required Days{" "}
                          <span
                            style={{
                              fontSize: "20px",
                              fontWeight: "bold",
                              color: "red",
                            }}
                          >
                            {" "}
                            *
                          </span>
                        </Form.Label>
                      </Col>
                      <Col sm={4}>
                        <p>
                          {" "}
                          <b> {formdata.visa_required_days}</b>{" "}
                        </p>
                      </Col>
                    </Row>
                  )}

                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url">
                        Intended Arrival Date{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <p>
                        {" "}
                        <b> {formdata.intended_arrival_date}</b>{" "}
                      </p>
                    </Col>
                  </Row>

                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url">
                        {" "}
                        Purpose of Visit{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <p>
                        {" "}
                        <b> {formdata.purpose_of_visit}</b>{" "}
                      </p>
                    </Col>
                  </Row>

                  {formdata && formdata.visaType === "BUSINESS VISA" ? (
                    <div>
                      {" "}
                      <div className="formvisa_apply">
                        {" "}
                        Contact Details of the Applicant's Company{" "}
                      </div>
                      <Row className="justify-content-md-center m-2">
                        <Col sm={5}>
                          <Form.Label htmlFor="basic-url">
                            {" "}
                            Company Name{" "}
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "red",
                              }}
                            >
                              {" "}
                              *
                            </span>
                          </Form.Label>
                        </Col>
                        <Col sm={4}>
                          <p>
                            {" "}
                            <b> {formdata.applicant_company_name}</b>{" "}
                          </p>
                        </Col>
                      </Row>
                      <Row className="justify-content-md-center m-2">
                        <Col sm={5}>
                          <Form.Label htmlFor="basic-url">
                            {" "}
                            Address{" "}
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "red",
                              }}
                            >
                              {" "}
                              *
                            </span>
                          </Form.Label>
                        </Col>
                        <Col sm={4}>
                          <p>
                            {" "}
                            <b> {formdata.applicant_company_add}</b>{" "}
                          </p>
                        </Col>
                      </Row>
                      <Row className="justify-content-md-center m-2">
                        <Col sm={5}>
                          <Form.Label htmlFor="basic-url">
                            {" "}
                            City{" "}
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "red",
                              }}
                            >
                              {" "}
                              *
                            </span>
                          </Form.Label>
                        </Col>
                        <Col sm={4}>
                          <p>
                            {" "}
                            <b> {formdata.applicant_company_city}</b>{" "}
                          </p>
                        </Col>
                      </Row>
                      <Row className="justify-content-md-center m-2">
                        <Col sm={5}>
                          <Form.Label htmlFor="basic-url">
                            {" "}
                            State{" "}
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "red",
                              }}
                            >
                              {" "}
                              *
                            </span>
                          </Form.Label>
                        </Col>
                        <Col sm={4}>
                          <p>
                            {" "}
                            <b> {formdata.applicant_company_state}</b>{" "}
                          </p>
                        </Col>
                      </Row>
                      <Row className="justify-content-md-center m-2">
                        <Col sm={5}>
                          <Form.Label htmlFor="basic-url">
                            {" "}
                            Zip/Postal Code{" "}
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "red",
                              }}
                            >
                              {" "}
                              *
                            </span>
                          </Form.Label>
                        </Col>
                        <Col sm={4}>
                          <p>
                            {" "}
                            <b> {formdata.applicant_company_zipcode}</b>{" "}
                          </p>
                        </Col>
                      </Row>
                      <Row className="justify-content-md-center m-2">
                        <Col sm={5}>
                          <Form.Label htmlFor="basic-url">
                            {" "}
                            Country or Region{" "}
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "red",
                              }}
                            >
                              {" "}
                              *
                            </span>
                          </Form.Label>
                        </Col>
                        <Col sm={4}>
                          <p>
                            {" "}
                            <b> {formdata.applicant_company_region}</b>{" "}
                          </p>
                        </Col>
                      </Row>
                      <Row className="justify-content-md-center m-2">
                        <Col sm={5}>
                          <Form.Label htmlFor="basic-url">
                            {" "}
                            Email address{" "}
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "red",
                              }}
                            >
                              {" "}
                              *
                            </span>
                          </Form.Label>
                        </Col>
                        <Col sm={4}>
                          <p>
                            {" "}
                            <b> {formdata.applicant_company_email}</b>{" "}
                          </p>
                        </Col>
                      </Row>{" "}
                      <div className="formvisa_apply">
                        {" "}
                        Contact Details of the Sri Lankan Company{" "}
                      </div>
                      <Row className="justify-content-md-center m-2">
                        <Col sm={5}>
                          <Form.Label htmlFor="basic-url">
                            {" "}
                            Company Name{" "}
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "red",
                              }}
                            >
                              {" "}
                              *
                            </span>
                          </Form.Label>
                        </Col>
                        <Col sm={4}>
                          <p>
                            {" "}
                            <b> {formdata.Sri_Lankan_Company_name}</b>{" "}
                          </p>
                        </Col>
                      </Row>
                      <Row className="justify-content-md-center m-2">
                        <Col sm={5}>
                          <Form.Label htmlFor="basic-url">
                            {" "}
                            Address{" "}
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "red",
                              }}
                            >
                              {" "}
                              *
                            </span>
                          </Form.Label>
                        </Col>
                        <Col sm={4}>
                          <p>
                            {" "}
                            <b> {formdata.Sri_Lankan_Company_address}</b>{" "}
                          </p>
                        </Col>
                      </Row>
                      <Row className="justify-content-md-center m-2">
                        <Col sm={5}>
                          <Form.Label htmlFor="basic-url">
                            {" "}
                            City{" "}
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "red",
                              }}
                            >
                              {" "}
                              *
                            </span>
                          </Form.Label>
                        </Col>
                        <Col sm={4}>
                          <p>
                            {" "}
                            <b> {formdata.Sri_Lankan_Company_city}</b>{" "}
                          </p>
                        </Col>
                      </Row>
                      <Row className="justify-content-md-center m-2">
                        <Col sm={5}>
                          <Form.Label htmlFor="basic-url">
                            {" "}
                            State{" "}
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "red",
                              }}
                            >
                              {" "}
                              *
                            </span>
                          </Form.Label>
                        </Col>
                        <Col sm={4}>
                          <p>
                            {" "}
                            <b> {formdata.Sri_Lankan_Company_state}</b>{" "}
                          </p>
                        </Col>
                      </Row>
                      <Row className="justify-content-md-center m-2">
                        <Col sm={5}>
                          <Form.Label htmlFor="basic-url">
                            {" "}
                            Zip/Postal Code{" "}
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "red",
                              }}
                            >
                              {" "}
                              *
                            </span>
                          </Form.Label>
                        </Col>
                        <Col sm={4}>
                          <p>
                            {" "}
                            <b> {formdata.Sri_Lankan_Company_zipcode}</b>{" "}
                          </p>
                        </Col>
                      </Row>
                      <Row className="justify-content-md-center m-2">
                        <Col sm={5}>
                          <Form.Label htmlFor="basic-url">
                            {" "}
                            Telephone Number{" "}
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "red",
                              }}
                            >
                              {" "}
                              *
                            </span>
                          </Form.Label>
                        </Col>
                        <Col sm={4}>
                          <p>
                            {" "}
                            <b> {formdata.Sri_Lankan_Company_telehone}</b>{" "}
                          </p>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <div className="formvisa_apply">Contact Details</div>
                      <Row className="justify-content-md-center m-2">
                        <Col sm={5}>
                          <Form.Label htmlFor="basic-url">
                            {" "}
                            Address Line 1 in Sri Lanka{" "}
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "red",
                              }}
                            >
                              {" "}
                              *
                            </span>
                          </Form.Label>
                        </Col>
                        <Col sm={4}>
                          <p>
                            {" "}
                            <b> {formdata.address_line1}</b>{" "}
                          </p>
                        </Col>
                      </Row>
                      <Row className="justify-content-md-center m-2">
                        <Col sm={5}>
                          <Form.Label htmlFor="basic-url">
                            {" "}
                            Address Line 2 in Sri Lanka{" "}
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "red",
                              }}
                            >
                              {" "}
                              *
                            </span>
                          </Form.Label>
                        </Col>
                        <Col sm={4}>
                          <p>
                            {" "}
                            <b> {formdata.address_line2}</b>{" "}
                          </p>
                        </Col>
                      </Row>
                      <Row className="justify-content-md-center m-2">
                        <Col sm={5}>
                          <Form.Label htmlFor="basic-url">
                            {" "}
                            City{" "}
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "red",
                              }}
                            >
                              {" "}
                              *
                            </span>
                          </Form.Label>
                        </Col>
                        <Col sm={4}>
                          <p>
                            {" "}
                            <b> {formdata.city}</b>{" "}
                          </p>
                        </Col>
                      </Row>
                      <Row className="justify-content-md-center m-2">
                        <Col sm={5}>
                          <Form.Label htmlFor="basic-url">
                            {" "}
                            State{" "}
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "red",
                              }}
                            >
                              {" "}
                              *
                            </span>
                          </Form.Label>
                        </Col>
                        <Col sm={4}>
                          <p>
                            {" "}
                            <b> {formdata.state}</b>{" "}
                          </p>
                        </Col>
                      </Row>
                      <Row className="justify-content-md-center m-2">
                        <Col sm={5}>
                          <Form.Label htmlFor="basic-url">
                            {" "}
                            Zip/Postal Code{" "}
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "red",
                              }}
                            >
                              {" "}
                              *
                            </span>
                          </Form.Label>
                        </Col>
                        <Col sm={4}>
                          <p>
                            {" "}
                            <b> {formdata.postal_code}</b>{" "}
                          </p>
                        </Col>
                      </Row>
                      <Row className="justify-content-md-center m-2">
                        <Col sm={5}>
                          <Form.Label htmlFor="basic-url">
                            {" "}
                            Country or Region{" "}
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "red",
                              }}
                            >
                              {" "}
                              *
                            </span>
                          </Form.Label>
                        </Col>
                        <Col sm={4}>
                          <p>
                            {" "}
                            <b> {formdata.Country_or_region}</b>{" "}
                          </p>
                        </Col>
                      </Row>
                      <Row className="justify-content-md-center m-2">
                        <Col sm={5}>
                          <Form.Label htmlFor="basic-url">
                            {" "}
                            Mobile/ Cell Phone Number{" "}
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "red",
                              }}
                            >
                              {" "}
                              *
                            </span>
                          </Form.Label>
                        </Col>
                        <Col sm={4}>
                          <p>
                            {" "}
                            <b> {formdata.mobile_number}</b>{" "}
                          </p>
                        </Col>
                      </Row>
                      <Row className="justify-content-md-center m-2">
                        <Col sm={5}>
                          <Form.Label htmlFor="basic-url">
                            {" "}
                            Address in Sri Lanka{" "}
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "red",
                              }}
                            >
                              {" "}
                              *
                            </span>
                          </Form.Label>
                        </Col>
                        <Col sm={4}>
                          <p>
                            {" "}
                            <b> {formdata.address_in_srilanka}</b>{" "}
                          </p>
                        </Col>
                      </Row>{" "}
                    </div>
                  )}

                  <div className="formvisa_apply">Declaration</div>

                  <Row className="justify-content-md-center m-2">
                    <Col sm={7}>
                      <Form.Label htmlFor="basic-url">
                        {" "}
                        1 . Do you have a valid residence visa to Sri Lanka?{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>

                    <Col sm={4}>
                      <div>
                        <p>
                          {" "}
                          <b> {formdata.valid_residence_visa}</b>{" "}
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center m-2">
                    <Col sm={7}>
                      <Form.Label htmlFor="basic-url">
                        {" "}
                        2 . Do you have a multiple entry visa to Sri Lanka?{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <p>
                        {" "}
                        <b> {formdata.multiple_entry_visa}</b>{" "}
                      </p>
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center m-2">
                    <Col sm={7}>
                      <Form.Label htmlFor="basic-url">
                        {" "}
                        3 . Are you currently in Sri Lanka with a valid ETA or
                        obtained an extension of visa?{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <p>
                        {" "}
                        <b> {formdata.currently_in_sri_lanka}</b>{" "}
                      </p>
                    </Col>
                  </Row>

                  <div className="formvisa_apply">Required Document</div>
                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Form.Label htmlFor="basic-url">
                        {" "}
                        Passport Copy{" "}
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {" "}
                          *
                        </span>
                      </Form.Label>
                    </Col>
                    <Col sm={4}>
                      <div className="preview_img">
                        <img
                          src={`${baseURL}/uploads/${formdata.passport_bio_page}`}
                          alt="upload-image"
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className="justify-content-md-center m-2">
                    <Col sm={5}>
                      <Button onClick={handleEdit}> Edit </Button>
                    </Col>
                    <Col sm={4}>
                      <Button onClick={Gopayment}> Next To Pay </Button>
                    </Col>
                  </Row>
                </Container>
              </Container>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Preview;

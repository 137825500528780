import { React } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousal from "../home/Carousal";
import Disclaimer from "../pages/Disclaimer";
import SidePage from "../home/SidePage";
import stepImage from '../images/srilanka_visa_page.png'

import "./page.css"
function Welcome() {
  return (
    <>
      <Carousal />
      <Container className="A4size">
        <Row>
          <Col sm={3}>
            <SidePage />
          </Col>
          <Col sm={9} className="">
            <div className="page-title">
              <h2> Welcome to the Sri Lanka eVisa </h2>
            </div>
            {/* content */}
            <div className="page-main-container">
              <p>
                {" "}
                Experience the convenience of obtaining your visa online for
                your trip to the beautiful island of Sri Lanka. Whether
                you&#39;re traveling for <b> tourism or business</b>, our eVisa
                system simplifies the application process, saving you time and
                effort.
              </p>
              <p className="page-sub-title">
                {" "}
                <b>What is Online Sri Lanka eVisa?</b>
              </p>
              <p>
                The <b> Online Sri Lanka eVisa</b> is an electronic travel
                authorization that allows eligible travelers to enter Sri Lanka
                for tourism, business, or transit purposes. The eVisa system
                simplifies the visa application process by allowing travelers to
                apply, pay, and receive their visa online, eliminating the need
                to visit a consulate or embassy.
              </p>

              <p>
                {" "}
                The eVisa program was introduced by the Government of{" "}
                <b> Sri Lanka</b> to promote tourism and facilitate easier
                access for visitors. It offers various types of visas, including
                tourist visas, business visas, and transit visas, depending on
                the purpose of the visit.
              </p>

              <p>
                {" "}
                To apply for an <b> Online Sri Lanka eVisa</b>, travelers need
                to complete an online application form, provide necessary
                documents such as a passport, passport-sized photograph and pay
                the required visa fee. The application is usually processed
                within a few days, and if approved, the eVisa is sent to the
                applicant via email.
              </p>

              <p>
                {" "}
                The <b> Online Sri Lanka eVisa </b>system enhances the
                efficiency of the visa application process, promotes tourism,
                and contributes to the economic development of Sri Lanka by
                attracting more visitors to the country.
              </p>

              <p className="page-sub-title">
                <b> Types of Sri Lanka eVisas</b>{" "}
              </p>
              <b>
                <p>1. Tourist Sri Lanka eVisa:</p>{" "}
              </b>
              <ul className="sub-order-list">
                <li>
                  Valid for short-term visits for sightseeing, visiting friends
                  and family, and holidaying.{" "}
                </li>
                <li> Available for single, double and multiple entries.</li>
              </ul>

              <b>
                <p> 2. Business Sri Lanka eVisa:</p>
              </b>
              <ul className="sub-order-list">
                <li>
                  Ideal for business meetings, conferences, and trade
                  negotiations.{" "}
                </li>
                <li> Available for single, double, and multiple entries.</li>
              </ul>

              <p className="page-sub-title">
                {" "}
                <b>Why Choose Sri Lanka eVisa </b>{" "}
              </p>

              <ul className="sub-order-list">
                <li>
                  {" "}
                  Convenience: Apply from anywhere in the world without visiting
                  an embassy or consulate.
                </li>
                <li>
                  {" "}
                  Time-Saving: Faster processing times mean you can plan your
                  trip without delay.
                </li>
                <li>
                  {" "}
                  Accessibility: Available 24/7 online, with support in multiple
                  languages.
                </li>
              </ul>

              <p className="page-sub-title">
                {" "}
                <b>Important Information </b>{" "}
              </p>

              <ul className="sub-order-list">
                <li>
                  {" "}
                  Ensure your passport is valid for at least 6 months from the
                  date of entry into Sri Lanka.
                </li>
                <li> Verify your eligibility for the eVisa before applying.</li>
                <li>
                  {" "}
                  Double-check all application details to avoid processing
                  issues.
                </li>
                <li>
                  {" "}
                  If the Sri Lankan eVisa application form is rejected by the
                  Sri Lankan government authorities due to furnishing by wrong
                  details by the visitors, our website
                  <b> www.esrilankaeta.com</b> cannot be held responsible in any
                  way.{" "}
                </li>
              </ul>
            </div>
            <div className="how-to-apply-info">
               <div className='how-to-apply'>
                  <h1 id="as2df" style={{ color: "#ff9b00e8" }}> How to Apply Sri Lanka </h1>
                  <h1> &nbsp; e-Visa</h1>
               </div>
               <h5>Receive your e-Visa on your E-mail by following these 3 steps</h5>
               <div className="image-container">
                  <img src={stepImage} alt="srilanka-visa3" className="fixed-height-image" />
               </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Disclaimer />
    </>
  );
}
export default Welcome;

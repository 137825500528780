import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import img34 from "../images/srilanka_banner.jpg"

function Carousal() {
  return (
    <>
      <div className='carousal'>
        <div className='image-container'>
          <img src={img34} alt='#' className='background-image' />
          <Container className='cont_btn'>
            <Row>
              <Link to="/apply" className='btn-apply'>
                <Col>
                  <h4 className='white'>Apply For ETA</h4>
                </Col>
              </Link>
              <Link to="/partialpay" className='btn-apply'>
                <Col>
                  <h4 className='white'> Partially Filled Form</h4>
                </Col>
              </Link>
              <Link to="/partialpay" className='btn-apply'>
                <Col>
                  <h4 className='white'>Visa Payment</h4>
                </Col>
              </Link>
            </Row>
          </Container>
        </div>
      </div>
    </>
  )
}

export default Carousal
import { React } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousal from "../home/Carousal";
import Disclaimer from "../pages/Disclaimer";
import SidePage from "../home/SidePage";
function Service() {
  return (

    <>
    

      <Carousal />
      <Container className="A4size">
        <Row>
          <Col sm={3}>
            <SidePage />
          </Col>
          <Col sm={9} className="">
          <div className="page-title">
          <h2 > E-Visa Sri Lanka Services</h2>
        </div>
        <div className="page-main-container">
          <p className='page-sub-title'><b>Value added Services(Sri Lanka) </b></p>

          <p>
            The International visitors who wish to apply for Sri Lankan e-Visa can choose to apply through
            the website. <b> www.esrilankaeta.com</b> is a service provider which facilitates the process of e-Visa.
            The Government of Sri Lanka is however the sole issuing authority of e-Visa. e-Visa application
            process requires accurate information to be submitted in order to ensure e-Visa. Our team of
            well-trained professionals are aware of all the official rules and regulations related to e-Visa
            application. Below we have enlisted the additional services we provide which are not given by
            the official website.
          </p>


          <p className='page-sub-title'><b>Rechecking of e-Visa application form before final submission. </b> </p>
          <p>
            A large number of e visa application forms are rejected by the Sri Lankan officials due to lack of
            correct information in the e-Visa application form. An expert team of professionals at e-Visa Sri
            Lanka minutely checks the e-Visa application form before final submission to the government
            officials. Any discrepancies in the application form is corrected promptly by our team.
          </p>

          <p className='page-sub-title'><b>24*7 Customer Email Support: </b></p>
          <p>
            e-Visa Sri Lanka operates a fully functional 24*7 customer care support. This service is provided
            keeping in mind the difficulties faced by countries whose office hours don’t match with the Sri
            Lankan office hours. The applicants can contact email for any queries or updates.
          </p>


          <p className='page-sub-title'><b>Recovery of e-Visa in case of loss/theft.  </b></p>

          <p>

            The e-Visa of the applicants are safely kept in our databases until 6 months from the visitor’s
            entry into Sri Lanka. The visitors can ask for another copy of their e-Visa at any time.
          </p>


          <p className='page-sub-title'><b> Constant updates on the e-Visa application. </b></p>

          <p>
            We constantly update the applicants about the status of their e-Visa application. The users can
            contact our website at any time to know about the status of their application forms. Our team
            instantly update the users if there is any requirement of additional documents. In case of
            rejection of the application by Sri Lankan authorities too we convey this information promptly to
            the users along with the referral notice.

          </p>


          <p className='page-sub-title'><b>No need to fill the application form again.</b></p>

          <p>
            All the details of our users are safely stored in our databases. The applicants who have once
            submitted their e-Visa application form through our website don’t need to fill the e visa again.
            They have to just provide their passport number and our team will fill in the details. This saves
            much precious time of our valuable clients.
          </p>

          <p className='page-sub-title'><b>A team of Interpreters to overcome language barriers. </b></p>

          <p>
            In order to tackle the problem of language barrier faced by our clients we have hired interpreters
            of all major international languages. The users can conveniently converse with our
            representatives in their mother tongue.
          </p>


          <p className='page-sub-title'><b>Live expert support team assistance during filling of application.  </b></p>
          <p> Our team of trained professionals provide live assistance to the applicants during e-Visa
            application process. The users can choose to make use of this assistance through phone or by
            email.</p>
          <p> Contact us :- <a href="mailto:info@esrilankaeta.com"><b> info@esrilankaeta.com</b></a></p>
        </div>
          </Col>
        </Row>
      </Container>
      <Disclaimer />
    </>
  )
}

export default Service
import React from 'react'
import '../home/container.css'
function Disclaimer() {
  return (
    <div>
            <div className="disclaimer">
            <p>
               <b style={{ fontSize: "large" }}>Disclaimer :- </b><br />
               <b> www.esrilankaeta.com </b> is an independent travel advisor and is not affiliated with or endorsed by
               any government agency or organization. By utilizing our services for the Sri Lanka e-Visa
               application, you acknowledge that <b> www.esrilankaeta.com</b> acts solely as a facilitator, and the
               approval of the e-Visa is subject to the discretion of Sri Lanka immigration authorities.
               Applicants are responsible for the accuracy of the information provided, and any discrepancies
               may lead to delays or rejections. Our company cannot guarantee specific processing times or
               the approval of the visa. It is the applicant&#39;s responsibility to verify their eligibility, adhere to the
               approved travel dates, and plan their itinerary accordingly. <b> www.esrilankaeta.com</b> is not liable for
               any issues arising from the e-Visa application process, including but not limited to delays,
               rejections, or changes in travel plans. The refund policy is outlined separately, and applicants
               are advised to review it before submitting an application. <b> www.esrilankaeta.com</b> reserves the
               right to modify these terms without prior notice.
            </p>
         </div>
      
    </div>
  )
}

export default Disclaimer

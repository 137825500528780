import React, { useCallback, useEffect, useState } from "react";
import "../App.css";
import { Button, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useParams } from "react-router-dom";
import { api, baseURL } from "../utils/api";

function Payment() {
  const [isChecked1, setIsChecked1] = useState(false);
  const handleCheckboxChange1 = (e) => {
    setIsChecked1(e.target.checked);
  };
  const { id } = useParams();
  const [formData, setFormData] = useState({});
  const [payAmount, setPayAmount] = useState("");

  const fetchApplicationDetail = useCallback(async () => {
    if (!id) return;
    try {
      const response = await api.get(`/api/srilanka/View-application/${id}`);
      if (response.data && response.data.status) {
        setFormData(response.data.data);
      }
    } catch (error) {
      alert(error.response.data.message || "something went wrong");
    }
  }, [id]);

  // const fetchpaymentAmount = useCallback(async () => {
  //   try {
  //     const response = await api.post("/api/srilanka/get-visa-fee", {
  //       countryName: formData.nationality,
  //       feeType: formData.sub_visa_type,
  //     });
  //     console.log("🚀 ~ fetchpaymentAmount ~ response:", response);
  //     if (response.data && response.data.success) {
  //       setPayAmount(response.data.data);
  //     }
  //   } catch (error) {
  //     alert(error.response?.data?.message || "Something went wrong");
  //   }
  // }, [formData.nationality, formData.sub_visa_type]);

  useEffect(() => {
    fetchApplicationDetail();
  }, [
    fetchApplicationDetail,
  
  ]);

  let paymentAmount = 0;

  // Check if formData and formData.visaType exist before proceeding
  if (formData?.visaType === "BUSINESS VISA") {
      paymentAmount = 85;
  } else if (formData?.visaType === "TOURIST VISA") {
      paymentAmount = 75;
  }
  
    const amount = paymentAmount * 100;
  const currency = "USD";
  const receiptId = "VISA00765";

  const paymentHandler = async (e) => {
    const response = await fetch(`${baseURL}/api/srilanka/order`, {
      method: "POST",
      body: JSON.stringify({
        amount,
        currency,
        receipt: receiptId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const order = await response.json();
    // console.log(order);

    var options = {
      key: "rzp_live_GiQR9cB7jSqyCR", // Enter the Key ID generated from the Dashboard
      // key: "rzp_test_0twaP547WNOmnJ", // Enter the Key ID generated from the Dashboard
      amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency,
      name: formData.surname, //your business name
      description: "Visa fee for srilanka",
      image: "https://example.com/your_logo",
      order_id: order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: async function (response) {
        const body = {
          ...response,
        };

        const validateRes = await fetch(
          `${baseURL}/api/srilanka/order/validate`,
          {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const jsonRes = await validateRes.json();
        console.log(jsonRes);
      },
      prefill: {
        //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
        name: formData.surname, //your customer's name
        email: formData.email,
        contact: formData.mobile_number, //Provide the customer's phone number for better conversion rates
      },
      notes: {
        address: formData.address_line1,
      },
      theme: {
        color: "#3399cc",
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      console.log("response", response);
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });
    rzp1.open();
    e.preventDefault();
  };

  return (
    <>
      {formData && (
        <div className="gsrilanka">
          <Container className="pagesa4">
            <div className="j">
              <h3 className="welcomeText">
                {" "}
                Terms &amp; Conditions before confirming your eVisa application{" "}
              </h3>
            </div>
            <hr />

            <h4> 1. All Evisa holders should be able to prove :</h4>
            <ul>
              <li>
                {" "}
                A round trip ticket to show at the port of entry in Sri Lanka
                (only if you are traveling by air).
              </li>
              <li>
                {" "}
                Evidence of sufficient funds to cover your expenses in Sri
                Lanka.{" "}
              </li>
            </ul>

            <h4> 2. Employment:</h4>
            <ul>
              {" "}
              <li>
                Holder of eVisa, should not engage in any form of employment,
                paid or unpaid, or in any trade or business other than specified
                in the eVisa, during the period of his/her stay in Sri Lanka
              </li>
            </ul>

            <h4> 3. Fees and Payments:</h4>
            <ul>
              <li>
                You agree that your credit card(s) will be billed immediately
                after submission of your application. Requirements and Fees
                related to processing of your application are subject to change
                from time to time and you agree that the eVisa processing fee
                once paid by you is not refundable and not transferable.
              </li>
            </ul>

            <h4> 4. Accuracy:</h4>
            <ul>
              <li>
                {" "}
                By submitting your application, you do certify that the
                information provided by you is true and correct.
              </li>
            </ul>

            <h4> 5. Limitations of use:</h4>
            <ul>
              <li>
                {" "}
                You may not use this site for any purpose other than the
                intended purpose.
              </li>
            </ul>
            <h4> 6. Application Submitting Terms</h4>
            <ul>
              <li> You must enter the passport number correctly</li>
              <li>
                {" "}
                You must enter nationality correctly as appears in the travel
                document
              </li>
              <li>You must enter your date of birth correctly</li>
              <li>
                {" "}
                You must recheck / crosscheck above information to ascertain
                that they have been entered correctly. You are required to pay
                for the visa again on arrival in case of any inconstancy in
                above particulars.
              </li>
            </ul>

            <ul>
              <li>
                {" "}
                You must make sure that the same travel document which was used
                for applying online visa is used for entering into Sri Lanka. In
                the event you produce a travel document other than the one used
                for applying online visa, you are required to pay again on
                arrival for the visa.
              </li>
            </ul>

            <h4> 7. On pressing &quot;Pay Now&quot;:</h4>
            <ul>
              <li>
                {" "}
                The application will be redirected to Payment Gateway to pay the
                visa fee and will be outside the control of Visa Online
                Application. The responsibility of security of transaction
                process and details on payment page will be of Payment gateway.
                Bank Payment Gateway accepts both OTP (One Time Password) and
                non-OTP transactions. In case of any issue, please contact your
                Bank. Please note that your application for e-Visa will not be
                submitted without fee payment. It should be done prior to 4 days
                of Journey date.
              </li>
            </ul>

            <div className="centerCol">
              <div className="welcomeText">
                <Container className="pagesa4">
                  <div className="paydiv">
                    <p> Application Number : {formData.sku_id}</p>

                    <p> fee : {paymentAmount} USD</p>
                  </div>

                  <p id="payhide">
                    {" "}
                    <b> Undertaking</b>
                  </p>

                  {/* <Row className="justify-content-md-center m-2 etavisatype kms"> */}
                  <Form>
                    <Form.Check
                      type="checkbox"
                      id="checkbox"
                      className="kmspaymentlast"
                      onChange={handleCheckboxChange1}
                      checked={isChecked1}
                      label={
                        <span className="custom-checkbox-label">
                          I solemnly declare that the information furnished by
                          me in this application is true and correct. I have not
                          willfully suppressed any information that is required,
                          in the event of issue of visa. I shall comply with the
                          terms and conditions subject to which the visa is
                          granted, and that. I shall not engage myself in any
                          paid or unpaid employment, then the Tourist/Business
                          purposes and that. I shall notify the Controller
                          General of Immigration and Emigration of any change in
                          my address during my stay in Sri Lanka.
                        </span>
                      }
                    ></Form.Check>
                  </Form>
                  {/* </Row> */}
                  <Row className="justify-content-md-center m-2">
                    <Col sm={2}>
                      <Button onClick={paymentHandler} disabled={!isChecked1}>
                        {" "}
                        Pay Now{" "}
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </Container>
        </div>
      )}
    </>
  );
}

export default Payment;
